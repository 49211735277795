import axios from 'axios';

// const apiBaseUrl = "https://save-from.net/api/convert"; // Save-form.net url 

const apiBaseUrl = "https://youtubdle.com/metainfo";


 // Save-form.net url 
// export default class DownloadService {
//     async downloadYoutubeVideo(payload){
//         return await axios.post(`${apiBaseUrl}`,payload).then(res => res.data);
//     }
// }



// Youtubdle.com downloader
export default class DownloadService {
    async downloadYoutubeVideo(url){
        return await axios.get(`${apiBaseUrl}?url=${url}`).then(res => res.data);
    }
}