import React, { useState, useEffect, useRef } from "react";
import { useTimeout, useInterval } from 'primereact/hooks';
import { Button } from "primereact/button";
import "./TvShows.css";
import { Card } from 'primereact/card';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import skeemSaam from '../assets/skeem-saam.png';
import { LinkContainer } from "react-router-bootstrap";
import mamazala from '../assets/mamazala.jpg';
import dlozami from '../assets/dlozami.jpg';
import ourPerfectWedding from '../assets/our-perfect-wedding.jpg';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import Video from './Video'
import BoxShows from "./BoxShows";
import ContentService from "../Service/ContentService";
import { Toast } from 'primereact/toast';
import DownloadFromYoutube from "../components/DownloadFromYoutube";
        
export default function TvShows() {
    const [layout, setLayout] = useState('grid');

    const [visible, setVisible] = useState(false);
    const [viewAllshows, setViewAllshows] = useState(false);
    const [selectedShow, setSelectedShow] = useState(null);
    const [shows, setShows] = useState(null);
    const [latestShows, setLatestShows] = useState(null);
    const [isLoadingEpisodes, setIsLoadingEpisodes] = useState(true);
    const [isLoadingShows, setIsLoadingShows] = useState(true);
    const [intialLoad, setIntialLoad] = useState(true);
    const contentService = new ContentService();

    const toast = useRef(null);
    // const [seconds, setIsLoadingShows] = useState(true);
    const [seconds, setSeconds] = useState(0);
    const [active, setActive] = useState(true);
    
    useEffect(() => {
        contentService.GetAllShows().then(res =>{
            setShows(res);
            setIsLoadingShows(false);
        })
        .catch(error =>{
            console.log("Error loading Latest episodes. ", error )
            setIsLoadingShows(false);
        });

        contentService.GetLatestEpisodes().then(res =>{

            console.log("EPS", res)
            setLatestShows(res);
            setIsLoadingEpisodes(false)
        })
        .catch(error =>{
            console.log("Error loading Shows. ", error )
            setIsLoadingEpisodes(false);
        });

        setIntialLoad(false);

    }, []);

    useInterval(
        () => {
            setSeconds((prevSecond) => (prevSecond === 9 ? showNotification() : prevSecond + 1)); //fn
        },
        1000,   //delay (ms)
        active  //condition (when)
    );

    const showNotification = () => {

        contentService.GetLatestEpisodes().then(res =>{
            if(!checkForEpisodeUpdates(res,latestShows))
            {
                toast.current.show({ severity: 'warn', summary: 'New Episode laoded' });
                setLatestShows(res);
            }
        })
        .catch(error =>{
            console.log("Error loading Shows. ", error )
            setIsLoadingEpisodes(false);
        });
        
        return 0;
    }

    function checkForEpisodeUpdates(list1, list2) {
        if (list1.length !== list2.length) {
          return false; // Lists have different lengths, so they are not the same
        }
      
        for (let i = 0; i < list1.length; i++) {
          const obj1 = list1[i];
          const obj2 = list2[i];
      
          // Compare properties of the objects
          for (let key in obj1) {
            if (obj1[key] !== obj2[key]) {
              return false; // Properties don't match, so the lists are not the same
            }
          }
        }
      
        return true; // All objects and their properties match, so the lists are the same
      }

    const image = showName =>{
            switch (showName) {
                case 'Skeem Saam':
                    return skeemSaam;
                case 'Mamazala':
                        return mamazala;
                case `Dloz'lami`:
                    return dlozami
                case 'Our Perfect Wedding':
                    return ourPerfectWedding;
                default:
                    break;
            }
    }

    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
        </div>
    );

    const titleWithIcon =(text)=> {
        return(
            <label class="text-700 font-bold"><i className="pi pi-folder-open" style={{ fontSize: '1.4rem'}}></i> {text}</label>
        );
    }

    const itemTemplate = (item) => {
        return (<div class="grid">
            <div className="col-12 md:col-12 lg:col-4" style={{padding:'1rem'}}>
                <Card   title={item.name} subTitle={item.subTitle} footer={footer(item)} className="md:w-20rem" header={header(item)}>
        </Card></div>
        </div>)
    };
    const toggleShowsView =(show) =>{
        setSelectedShow(show);
        setViewAllshows(!viewAllshows);
    }

    const itemTvShowsTemplate = (item) => {
        return (<div class="grid" style={{cursor:"pointer"}} onClick={_ => toggleShowsView(item)}>
            <div className="col-12 md:col-12 lg:col-4" style={{padding:'1rem'}}>
                <Card  title={titleWithIcon(item.name)}  className="md:w-20rem" header={headerTvShows(item)}>
        </Card></div>
        </div>)
    };

    const headerTvShows = item => (<>
            <img alt="Card" src={image(item.name)} className="md:w-20rem md:h-12rem"/>
        </>
    );
    const header = item => (
        <img alt="Card" src={image(item.name)} className="md:w-20rem md:h-12rem"/>
    );

    const footer= (item) =>(
        <div className="flex flex-wrap justify-content-end gap-2" text style={{marginTop:'1rem'}}>
            <LinkContainer to={`/${item.contentPath}?id=${item.videoId}`} style={{color:"#5F9EA0"}}>
                <Button label="Watch" icon="pi pi-eye" text raised/>
            </LinkContainer>

{/* KEY FUNCTIONALITY */}
            {/* <a  text raised href={item.downloadLink}>
                 <Button label={"Download "} icon="pi pi-download" text className=" p-button-secondary" raised/>
             </a> */}
        </div>
    );

    const headerContainer = () => {
        return (
            <div className="justify-content-start" >
                <h2 class="text-900 w-16rem h-2rem  font-bold" style={{textAlign:'start'}}>Latest episodes </h2>
            </div>
        );
    };

    const headerTvShowsContainer = () => {
        return (
            <div className="justify-content-start" >
                <h2 class="text-900 w-16rem h-2rem  font-bold" style={{textAlign:'start'}}> All Shows</h2>
            </div>
        );
    };

  return (
    <div className="card" style={{margin:'30px'}}>

        { isLoadingEpisodes && <>
            <h2 class="text-900 w-16rem h-2rem  font-bold" style={{textAlign:'start'}}>Latest episodes </h2>
            <Skeleton className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="10rem" height="4rem"></Skeleton>
        </>}

         {!viewAllshows && !isLoadingEpisodes && <div>
            <DataView value={latestShows} itemTemplate={itemTemplate} layout={layout} header={headerContainer()} />
            <DataView value={shows} itemTemplate={itemTvShowsTemplate} layout={layout} header={headerTvShowsContainer()} />
         </div>}


         { viewAllshows && !isLoadingEpisodes && <div>
            <BoxShows show={selectedShow} back={toggleShowsView}/>
            {/* <DataView value={latestTvShows} itemTemplate={itemTemplate} layout={layout} header={headerContainer()} />
            <DataView value={tvShows} itemTemplate={itemTvShowsTemplate} layout={layout} header={headerTvShowsContainer()} /> */}
         </div>}

            { isLoadingShows && <>
            <h2 class="text-900 w-16rem h-2rem  font-bold" style={{textAlign:'start'}}> All Shows</h2>
            <Skeleton className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="10rem" height="4rem"></Skeleton>
        </>}

        { !intialLoad && <Toast ref={toast} />}
    </div>
  );
}