import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { useMountEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';
import { Chip } from "primereact/chip";
import "../TvShows.css";
import Video from "../Video";
import DownloadFromYoutube from "../../components/DownloadFromYoutube";
import { LinkContainer } from "react-router-bootstrap";
import ContentService from "../../Service/ContentService";
import BoxShows from "../BoxShows";
import ApprovalShows from "./ApprovalShows";
import { Skeleton } from 'primereact/skeleton';

export default function Approvals() {
  const contentService = new ContentService();
  const [shows, setShows] = useState(null);
  const [selectedContent, setSelectedContent] = useState({});
  const [isDefaultView, setIsDefaultView] = useState(false);
  const [isLoadingShows, setIsLoadingShows] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const msgs = useRef(null);

  useEffect(() => {

    
  //   async GetShowsWithEpisodes(){
  //     return await axios.get(`${apiBaseUrl}GetShowsWithEpisodes`).then(res => res.data);
  // }
  // async GetEpisodesHistory(){
  //     return await axios.get(`${apiBaseUrl}GetEpisodesId`).then(res => res.data);
  // }

    const fetchData = async () => {
      try {
        const episodeHistory = await contentService.GetEpisodesHistory();

        const _shows = await contentService.GetAllShows();

        // const oneShow = [_shows[3]]; // Sleect single show 

        const showPromises = _shows.map(async (show) => {
          const availableEpisodes = await contentService.GetAvailableEpisodes(show.keywords);

          // const filteredEpisodes = getUniqueObjectsById(availableEpisodes );

          // show.episodes = availableEpisodes.items.map((item) => ({
          //   videoId: item.id.videoId,
          //   description: item.snippet.description,
          //   videoTitle: item.snippet.title,
          //   showId: show.id,
          //   datePosted: item.snippet.publishTime,
          //   contentPath: show.contentPath,
          //   imageURL: item.snippet.thumbnails.high.url,
          //   subTitle: "Episode " + formattedDate(item.snippet.publishTime),
          //   name: show.name,
          //   watchLink: `https://www.youtube.com/embed/${item.id.videoId}/?autoplay=0`,
          //   downloadLink: `https://www.youtubdle.com/watch?v=${item.id.videoId}`,
          // }));
          var filteredEpisodes = []; 

           availableEpisodes.items.map((item) => {
            if(!episodeHistory.includes(item.id.videoId))
                filteredEpisodes.push({
                videoId: item.id.videoId,
                description: item.snippet.description,
                videoTitle: item.snippet.title,
                showId: show.id,
                datePosted: item.snippet.publishTime,
                contentPath: show.contentPath,
                imageURL: item.snippet.thumbnails.high.url,
                subTitle: "Episode " + formattedDate(item.snippet.publishTime),
                name: show.name,
                watchLink: `https://www.youtube.com/embed/${item.id.videoId}/?autoplay=0`,
                downloadLink: `https://www.youtubdle.com/watch?v=${item.id.videoId}`,
              })
        });

          show.episodes =filteredEpisodes;

          return show;
        });
        const updatedShows = await Promise.all(showPromises);
        setShows(updatedShows);
        setIsLoadingShows(false);
      } catch (error) {
        setShows([]);
        console.log("Error loading Shows episodes. ", error);
        setIsLoadingShows(false);
        msgs.current.show(
          { sticky: true, severity: 'info', summary: 'Google Api key error. The request cannot be completed because you have exceeded your qouta', detail: '  ', closable: false }
      );
      }
    };

    const formattedDate =(publishDate) => {
      const date = new Date(publishDate);
      return  date.toLocaleDateString("en-US", { day: "numeric", month: "short", year: "numeric" });
    }
    fetchData();
  }, []);


  function getUniqueObjectsById(list1, list2) {
    const idSet = new Set();
    const uniqueObjects = [];
  
    // Process objects from the first list
    for (const obj of list1) {
      if (!idSet.has(obj.ID)) {
        idSet.add(obj.ID);
        uniqueObjects.push(obj);
      }
    }
  
    // Process objects from the second list
    for (const obj of list2) {
      if (!idSet.has(obj.ID)) {
        idSet.add(obj.ID);
        uniqueObjects.push(obj);
      }
    }
  
    return uniqueObjects;
  }

  const removeEpisodeByVideoId = (show, id)=> {

    const index = show.episodes.findIndex(episode => episode.videoId === id);
    const showIndex = shows.findIndex(obj => obj.id === show.id);

    if (index !== -1) {
        show.episodes.splice(index, 1);
        
        let lastestShows = [shows];

        lastestShows[showIndex] =  show;

        setShows(lastestShows);
    } else {
        console.log(`Episode with id ${id} not found.`);
    }
}

  const approveEpisode =(show, episode)=> {

    //Remove Episode after upload 
    // const index = show.episodes.findIndex(episode => episode.videoId === id);
    // const showIndex = shows.findIndex(obj => obj.id === show.id);

    // if (index !== -1) {
    //     show.episodes.splice(index, 1);
        
    //     let lastestShows = [shows];

    //     lastestShows[showIndex] =  show;

    //     setShows(lastestShows);
    // } else {
    //     console.log(`Episode with id ${id} not found.`);
    // }

    contentService.AddEpisode(episode).then(res =>{
      console.log("Response", res);
    }).
    catch(error =>{
      console.log("Error uploading Episode", error);
    });

  }


  const recreateKeys = ()=> {

    //Set another key 
    contentService.GenerateApiKey().then(_ => {
      
      window.location.reload();
      //Reload website 
    })
    .catch(error => {
      console.error("Error re creating api key ", error);
    })

  }

  const showsEpisodes = (shows) => {
    return shows.map((show) => {
      return <ApprovalShows key={show.id} show={show} removeEpisodeByVideoId = {removeEpisodeByVideoId} approveEpisode = {approveEpisode}/>;
    });
  };

  return (
    <div className="grid" style={{ margin: "30px", textAlign: "center", backgroundColor: "white" }}>
      <div className="col-12" style={{textAlign: "right"}}>
        <LinkContainer to={`/episode-manage`} style={{color:"#5F9EA0"}}>
          <Button label="Manage" icon="pi pi-wrench" text raised/>
        </LinkContainer>
      </div>

      {!isLoadingShows  && showsEpisodes(shows)}

      { isLoadingShows && <>
            <Skeleton className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="10rem" height="4rem"></Skeleton>
        </>}

      <div className="col-12" style={{textAlign: "center"}}>
      <div className="card flex justify-content-center">
      <Messages ref={msgs} />
        </div>
      <Button label="Create another key" onClick={_ => {recreateKeys()}} size="small" icon="pi pi-wrench" text raised/>
      </div>
      
    </div>
  );
}
