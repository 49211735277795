import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import "../TvShows.css";
import Video from "../Video";
import DownloadFromYoutube from "../../components/DownloadFromYoutube";
import { LinkContainer } from "react-router-bootstrap";
import ContentService from "../../Service/ContentService";
import BoxShows from "../BoxShows";
import ApprovalShows from "./ApprovalShows";
import { Skeleton } from 'primereact/skeleton';

export default function Manage() {
  const contentService = new ContentService();
  const [shows, setShows] = useState(null);
  const [selectedContent, setSelectedContent] = useState({});
  const [isDefaultView, setIsDefaultView] = useState(false);
  const [isLoadingShows, setIsLoadingShows] = useState(true);
  const [notFound, setNotFound] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const _shows = await contentService.GetShowsWithEpisodes();

        setShows(_shows);
        setIsLoadingShows(false);
      } catch (error) {
        setShows([]);
        console.log("Error loading Shows episodes. ", error);
        setIsLoadingShows(false);
      }
    };

    fetchData();
  }, []);

  const removeEpisodeByVideoId = (show, id)=> {

    contentService.DeleteEpisode(id).then(_ =>{
      const index = show.episodes.findIndex(episode => episode.videoId === id);
      const showIndex = shows.findIndex(obj => obj.id === show.id);
  
      if (index !== -1) {
          show.episodes.splice(index, 1);
          
          let lastestShows = [shows];
  
          lastestShows[showIndex] =  show;
  
          setShows(lastestShows);
      } else {
          console.log(`Episode with id ${id} not found.`);
      }
    })
    .catch(error =>{
        console.log(`Episode with id ${id} not found.`, error);
    });

   }

    const approveEpisode =(show, episode)=> {

      contentService.AddEpisode(episode).then(res =>{
        console.log("Response", res);
      }).
      catch(error =>{
        console.log("Error uploading Episode", error);
      });

    }


  const showsEpisodes = (shows) => {
    return shows.map((show) => {
      return <ApprovalShows key={show.id} show={show} isManage={true} removeEpisodeByVideoId = {removeEpisodeByVideoId} approveEpisode = {approveEpisode}/>;
    });
  };

  return (
    <div className="grid" style={{ margin: "30px", textAlign: "center", backgroundColor: "white" }}>
      <div className="col-12" style={{textAlign: "right"}}>
      <LinkContainer to={`/episode-approvals`} style={{color:"#5F9EA0"}}>
        <Button label="Approvals" icon="pi pi-check" text raised/>
      </LinkContainer>
      </div>
      
      {!isLoadingShows  && showsEpisodes(shows)}

      { isLoadingShows && <>
            <Skeleton className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="10rem" height="4rem"></Skeleton>
        </>}

    </div>
  );
}
