import axios from 'axios';

const apiBaseUrl = "https://api.turnupcentral.co.za/api/shows/";

const youtubeAPISearch = `https://youtube.googleapis.com/youtube/v3/search?part=snippet`;

export default class ContentService {
    async GetAllShows(){
        return await axios.get(`${apiBaseUrl}GetAllShows`).then(res => res.data);
    }

    async GetShowsWithEpisodes(){
        return await axios.get(`${apiBaseUrl}GetShowsWithEpisodes`).then(res => res.data);
    }

    async GetEpisodesHistory(){
        return await axios.get(`${apiBaseUrl}GetEpisodesId`).then(res => res.data);
    }

    async GetLatestEpisodes(){
        return await axios.get(`${apiBaseUrl}GetLatestEpisodes`).then(res => res.data);
    }

    async GetLatestEpisodes(){
        return await axios.get(`${apiBaseUrl}GetLatestEpisodes`).then(res => res.data);
    }

    async GetShowEpisodes(id){
        return await axios.get(`${apiBaseUrl}GetEpisodesByShowId?id=${id}`).then(res => res.data);
    }

    async AddEpisode(episode){
        return await axios.post(`${apiBaseUrl}AddEpisode`, episode).then(res => res.data);
    }

    async DeleteEpisode(id){
        return await axios.post(`${apiBaseUrl}DeleteEpisode?episodeId=${id}`).then(res => res.data);
    }

    async GetShowEpisodesByUrlPath(urlPath){
        return await axios.get(`${apiBaseUrl}GetShowEpisodesByUrlPath?urlPath=${urlPath}`).then(res => res.data);
    }

    async GetEpisodesById(id){
        return await axios.get(`${apiBaseUrl}GetEpisodesById?id=${id}`).then(res => res.data);
    }

    async GetAvailableEpisodes(keywords){
        const key = await this.GetActiveAPIKey();
        return await axios.get(`${youtubeAPISearch}&key=${key}&type=video&order=date&videoDuration=long&q=${keywords}`).then(res => res.data);
    }

    // API Keys
    async GetActiveAPIKey(){
        return await axios.get(`${apiBaseUrl}GetActiveAPIKey`).then(res => res.data);
    }

    async GenerateApiKey(){
        return await axios.post(`${apiBaseUrl}GenerateApiKey`).then(res => res.data);
    }
}