import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import "../TvShows.css";
import { Card } from 'primereact/card';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { LinkContainer } from "react-router-bootstrap";
import { Dialog } from 'primereact/dialog';
import Video from '../Video'

export default function ApprovalShows(props) {
    const [layout, setLayout] = useState('grid');
    const [selectedContent, setSelectedContent] = useState({});
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        }, []);

    const footerContent = (
        <>
        {
            props.isManage ?
            <div>
                <Button label="Delete" icon="pi pi-times" onClick={() => RejectContent()} className="p-button-secondary" text raised />
            </div>
            :
            <div>
                <Button label="Reject" icon="pi pi-times" onClick={() => RejectEpisode()} className="p-button-secondary" text raised />
                <Button label="Approve" icon="pi pi-check" onClick={() => approveEpisode()} className="p-button-success" text raised />
            </div>
        }
        </>
    );

    const itemTemplate = (item) => {
        return (<div class="grid">
            <div className="col-12 md:col-12 lg:col-4" style={{padding:'1rem'}}>
                <Card  title={item.videoTitle?.substring(0,22)} subTitle={item.subTitle} footer={footer(item)} className="md:w-20rem" header={header(item)}>
        </Card></div>
        </div>)
    };

    const header = (item) => (
        <img alt="Card" src={item.imageURL} className="md:w-20rem md:h-12rem"/>
    );

    const footer= (item) =>(
        <>{
            props.isManage ? 
                <div className="flex flex-wrap justify-content-end gap-2" text style={{marginTop:'1rem'}}>
                    <Button label="Delete" className=" p-button-secondary" icon="pi pi-times" text  raised onClick={() => RejectContent(item)} />
                    <Button label="View" icon="pi pi-eye" text style={{color:"#5F9EA0"}} raised onClick={() => WatchContent(item)} />
                </div>
            :
                <div className="flex flex-wrap justify-content-end gap-2" text style={{marginTop:'1rem'}}>
                <Button label="Reject" className=" p-button-secondary" icon="pi pi-times" text  raised onClick={() => RejectEpisode(item)} />
                <Button label="Approve" icon="pi pi-download" text style={{color:"#5F9EA0"}} raised onClick={() => WatchContent(item)} />
        </div>
        }
        </>
    );

    const RejectContent = (item) => {
        setVisible(false)

        console.log("selectedContent", selectedContent)
        if(item)
            props.removeEpisodeByVideoId(props.show,item.videoId);
         else 
           props.removeEpisodeByVideoId(props.show,selectedContent.videoId);
    }

    const RejectEpisode = (item) => {
        setVisible(false)
        if(item)
           props.removeEpisodeByVideoId(props.show,item.videoId);
        else
           props.removeEpisodeByVideoId(props.show,selectedContent.videoId);
    }

    const approveEpisode = () => {
        setVisible(false)
        props.approveEpisode(props.show, selectedContent);
    }

    const headerContainer = () => {
        return (
            <div className="justify-content-start">
                <div className="grid">
                    <div className="col-8 justify-content-start"><h4 class="text-900  h-1rem  font-bold" style={{textAlign:'start'}}>{props.show.name} Latest episodes </h4></div>
                </div>
            </div>
        );
    };
    
    const WatchContent =(episode)=>{
        setVisible(true);
        setSelectedContent(episode);
    }
  return (
    <div className="row" style={{padding:'20px'}}>
        {props.show.episodes?.length > 0 &&  <DataView value={props.show.episodes} itemTemplate={itemTemplate} layout={layout} header={headerContainer()} />}
          
        <Dialog header={"Episode Approval " + selectedContent.videoTitle} visible={visible} style={{ }} onHide={() => setVisible(false)} footer={footerContent}>
        <Video watchLink={selectedContent.watchLink} />
        </Dialog>

    </div>
  );
}