import React, { useState, useRef } from "react";
import "primereact/resources/themes/md-light-indigo/theme.css"


const  PrivacyPolicy = () => {
    const selectedContent = {
        date: '2023-08-13',
        email: 'info@turnupcentral.co.za'
        // Add other properties as needed
      };

      
  return (
<div
      className="col-12 surface-0"
      style={{ padding: "60px", paddingTop: "10px", textAlign:'left'}}
    >
 <h1>Privacy Policy</h1>

<p>Effective Date: {selectedContent.date}</p>

<p>Welcome to Turnup Central, a website focused on providing you with the latest TV shows in South Africa. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our services.</p>

<h2>Information We Collect</h2>

<p>We may collect the following types of information:</p>

<ul>
    <li>Personal Information: We may collect your name, email address, and other contact information when you sign up for our newsletter or create an account on our website.</li>
    <li>Usage Data: We automatically collect certain information when you interact with our website, such as your IP address, browser type, device information, and the pages you visit.</li>
    <li>Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience, analyze usage patterns, and personalize content. You can control cookie preferences in your browser settings.</li>
</ul>

<h2>How We Use Your Information</h2>

<p>We use the information we collect for the following purposes:</p>

<ul >
    <li>To provide and improve our services, including delivering the latest TV show updates and personalized recommendations.</li>
    <li>To communicate with you, respond to your inquiries, and send you important updates and promotions.</li>
    <li>To analyze usage patterns, perform research, and enhance the functionality and security of our website.</li>
</ul>

<h2>Sharing Your Information</h2>

<p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with:</p>

<ul>
    <li>Service Providers: We may engage trusted third-party service providers to assist us in delivering our services, such as email marketing platforms. These providers are bound by confidentiality agreements and are not allowed to use your information for other purposes.</li>
    <li>Legal Requirements: We may disclose your information to comply with applicable laws, regulations, or legal processes.</li>
</ul>

<h2>Security</h2>

<p>We take reasonable measures to protect your personal information. However, no data transmission over the internet or storage system is completely secure. While we strive to protect your data, we cannot guarantee its absolute security.</p>

<h2>Your Choices</h2>

<p>You have the right to:</p>

<ul>
    <li>Access, correct, or update your personal information.</li>
    <li>Opt-out of receiving promotional communications.</li>
    <li>Request the deletion of your account and personal data.</li>
</ul>

<h2>Contact Us</h2>

<p>If you have any questions about our Privacy Policy or how we handle your data, please contact us at <a href={"mailto:" + selectedContent.email}>{selectedContent.email}</a>.</p>

<p>Last Updated: {selectedContent.date}</p>
    </div>
  );
}

export default PrivacyPolicy