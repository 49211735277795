import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound"
import Login from "./containers/Login"
import TvShows from "./containers/TvShows";
// import WatchContent from "./containers/WatchContent";
import Growth from "./containers/Growth";
import WatchSkeemSaam from "./containers/WatchSkeemSaam";
import WatchDloziLam from "./containers/WatchDloziLam";
import WatchMamazala from "./containers/WatchMamazala";
import WatchOurPerfectWedding from "./containers/WatchOurPerfectWedding"
import Approvals from "./containers/Admin/Approvals";
import Manage from "./containers/Admin/Manage";

import PrivacyPolicy from "./containers/PrivacyPolicy";

import AboutUs from "./containers/AboutUs";


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <TvShows />
      </Route>

      <Route exact path="/login">
        <Login />
      </Route>

      <Route exact path="/about-us">
        <AboutUs />
      </Route>

      <Route exact path="/privacy-policy">
        <PrivacyPolicy />
      </Route>

      <Route exact path="/growth">
        <Growth />
      </Route>

      <Route exact path="/tv-shows">
        <TvShows />
      </Route>
      
      <Route exact path="/watch-skeem-saam">
        <WatchSkeemSaam />
      </Route>

      <Route exact path="/watch-dlozi-lami">
        <WatchDloziLam />
      </Route>

      <Route exact path="/watch-mamazala">
        <WatchMamazala />
      </Route>

      <Route exact path="/episode-approvals">
        <Approvals />
      </Route>

      <Route exact path="/episode-manage">
        <Manage />
      </Route>

      <Route exact path="/watch-our-perfect-wedding">
        <WatchOurPerfectWedding />
      </Route>

      <Route>
        <NotFound />
      </Route>
      
      
    </Switch>
  );
}