import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import "./TvShows.css";
import { Card } from 'primereact/card';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import skeemSaam from '../assets/skeem-saam.png';
import { LinkContainer } from "react-router-bootstrap";
import mamazala from '../assets/mamazala.jpg';
import dlozami from '../assets/dlozami.jpg';
import ourPerfectWedding from '../assets/our-perfect-wedding.jpg';
import { Dialog } from 'primereact/dialog';
import ContentService from "../Service/ContentService";
import Video from './Video'

export default function BoxShows(props) {
    const contentService = new ContentService();
    const [layout, setLayout] = useState('grid');
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [episodes, setEpisodes] = useState([]);

    useEffect(() => {
        contentService.GetShowEpisodes(props.show.id)
        .then((res) => {
            setEpisodes(res);
            
            console.log("Epis", res)
        })
        .catch((error) => {
            setEpisodes([]);
          console.log("Error loading Show episodes. ", error);
        });

        window.scrollTo(0, 0);
        }, []);

    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
        </div>
    );

    const image = showName =>{
        switch (showName) {
            case 'Skeem Saam':
                return skeemSaam;
            case 'Mamazala':
                    return mamazala;
            case `Dloz'lami`:
                return dlozami
            case 'Our Perfect Wedding':
                return ourPerfectWedding;
            default:
                break;
        }
    }
        
    const itemTemplate = (item) => {
        return (<div class="grid">
            <div className="col-12 md:col-12 lg:col-4" style={{padding:'1rem'}}>
                <Card  title={props.show.name} subTitle={item.subTitle} footer={footer(item)} className="md:w-20rem" header={header(item)}>
        </Card></div>
        </div>)
    };

    const header = () => (
        <img alt="Card" src={image(props.show.name)} className="md:w-20rem md:h-12rem"/>
    );

    const footer= (item) =>(
        <div className="flex flex-wrap justify-content-end gap-2" text style={{marginTop:'1rem'}}>
            <LinkContainer to={`/${item.contentPath}?id=${item.videoId}`} style={{color:"#5F9EA0"}}>
                <Button label="Watch" icon="pi pi-eye" text raised/>
            </LinkContainer>

            {/* KEY FUNCTIONALITY */}
            {/* <a  text raised href={item.downloadLink}>
                 <Button label={"Download "} icon="pi pi-download" text className=" p-button-secondary" raised/>
             </a> */}
        </div>
    );

    const headerContainer = () => {
        return (
            <div className="justify-content-start">
                <div className="grid">
                    <div className="col-4 flex flex-wrap  justify-content-start"><Button onClick={_ => props.back(null)} label="Back" severity="Primary" text icon="pi pi-arrow-left" style={{color:"#5F9EA0", marginTop:'25px'}}/></div>
                    <div className="col-8 justify-content-start"><h4 class="text-900  h-3rem  font-bold" style={{textAlign:'start'}}>{props.show.name} Latest episodes </h4></div>
                </div>
                
            </div>
        );
    };
    
  return (
    <div className="card">
        <DataView value={episodes} itemTemplate={itemTemplate} layout={layout} header={headerContainer()} />

    </div>
  );
}