import React, { useState, useEffect, useRef } from "react";
import { Link,useHistory } from "react-router-dom";
import { Image, Nav, Navbar, NavItem } from "react-bootstrap";
import "./App.css";

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/contextLib";
import { Tag } from 'primereact/tag';
import logo from './assets/turnup-central.png'
import { useMountEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';


function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isUserCompanyAdmin, userIsCompanyAdmin] = useState(false);
  const [isUserCompanyCashier, userIsCompanyCashier] = useState(false);
  const [isUserCompanySupervisor, userIsCompanySupervisor] = useState(false);
  const [isDevelopment, setIsDevelopment] = useState(false);

  const [config, setConfig] = useState(null);
  const history = useHistory();
  const msgs = useRef(null);

  useEffect(() => {
    var host = window.location.origin;

    if(host.includes("localhost:3000"))
       setIsDevelopment(true);

    onLoad();
  }, []);

  useMountEffect(() => {

    /* Uncomment If you want to be active */
    // msgs.current.show([
    //     { sticky: true, severity: 'warn', summary: 'This website is still under construction. Content may be inaccurate', closable: false },
    // ]);
  });

  async function onLoad() {
    try {
      //Check if they LoggedIn
      var status = window.localStorage.getItem("user") !== null ? true : false;
      userHasAuthenticated(status);

      if (status) {
        var user = JSON.parse(localStorage.getItem("user"));

        setConfig( {
          headers : { Authorization: `Bearer ${user.token}`}
        });

        if(user.userType === 3900)
          {
             userIsCompanyAdmin(true);
             userIsCompanyCashier(false);
             userIsCompanySupervisor(false);
          }
          else if(user.userType === 720){
            userIsCompanyAdmin(false);
            userIsCompanyCashier(true);
            userIsCompanySupervisor(false);
            history.push("/Transactions");
          }
          else if(user.userType === 330){
            userIsCompanyAdmin(false);
            userIsCompanyCashier(false);
            userIsCompanySupervisor(true);
          }
      }
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  function handleLogout() {
    userHasAuthenticated(false);
    //Clear Local storage
    userIsCompanyAdmin(false);
    userIsCompanyCashier(false);
    userIsCompanySupervisor(false);
    window.localStorage.clear();

    //Redirect to home 
    history.push("/");
  }

  return (
    <div className="App">
      <Navbar fluid collapseOnSelect style={{backgroundColor:"#5F9EA0"}}> 
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/" >
          <img src={logo} style={{maxHeight:'45px', padding:'0px', marginTop:'-14px'}}/>
          </Link>
          </Navbar.Brand>
            <label  style={{fontSize:'23px', fontWeight:'550', color:'white',marginTop:"10px", fontFamily:"Optima"}}>Turnup Central</label>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
        <Nav pullRight>
            <LinkContainer to="/tv-shows">
              <NavItem>TV Shows</NavItem>
            </LinkContainer>

            <LinkContainer to="/about-us">
              <NavItem>About us</NavItem>
            </LinkContainer>

            <LinkContainer to="/privacy-policy">
              <NavItem>Privacy Policy</NavItem>
            </LinkContainer>

           { isDevelopment && <>
            <LinkContainer to="/trends" >
              <NavItem>Trends</NavItem>
            </LinkContainer>

            <LinkContainer to="/gossip">
              <NavItem>Gossip</NavItem>
            </LinkContainer>
            
            <LinkContainer to="/events">
              <NavItem>Events</NavItem>
            </LinkContainer>


            <LinkContainer to="/gaming">
              <NavItem>Gaming</NavItem>
            </LinkContainer>

            <LinkContainer to="/growth">
              <NavItem>Growth</NavItem>
            </LinkContainer>

            <LinkContainer to="/fashion">
              <NavItem>Fashion</NavItem>
            </LinkContainer>

            <LinkContainer to="/episode-approvals">
              <NavItem>Approvals</NavItem>
            </LinkContainer>

            <LinkContainer to="/episode-manage">
              <NavItem>Manage</NavItem>
            </LinkContainer>

            <LinkContainer to="/login">
              <NavItem>Login</NavItem>
            </LinkContainer> </>}
        </Nav>
        {/* width="60px" height="40px"  alt="Logo image" style="margin-left: 10px;" */}
{/* 
        <Nav pullRight>

        {isAuthenticated && isUserCompanyAdmin
        ? <>
        <LinkContainer to="/testPage">
              <NavItem>Test Page</NavItem>
            </LinkContainer>
            <LinkContainer to="/Employees">
              <NavItem>Employees</NavItem>
            </LinkContainer>

            <LinkContainer to="/Suppliers">
              <NavItem>Suppliers</NavItem>
            </LinkContainer>

            <LinkContainer to="/Products">
              <NavItem>Products</NavItem>
            </LinkContainer>
            <LinkContainer to="/Transactions">
              <NavItem>Transactions</NavItem>
            </LinkContainer>
            <NavItem onClick={handleLogout}>Logout</NavItem>
          </>
        :
        <>
         {!isAuthenticated && <LinkContainer to="/login">
              <NavItem>Login</NavItem>
            </LinkContainer>}
        </>
         }

         {!isAuthenticated && !isUserCompanyAdmin ? <></> : isAuthenticated && !isUserCompanyAdmin  ? <NavItem onClick={handleLogout}>Logout</NavItem> : <></> }<></>
          </Nav> */}
        </Navbar.Collapse>
      </Navbar>
      <Messages ref={msgs} />

      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, isUserCompanyAdmin, userIsCompanyAdmin,isUserCompanyCashier, userIsCompanyCashier,isUserCompanySupervisor, userIsCompanySupervisor,config,setConfig}}>
        <Routes />
      </AppContext.Provider>


    </div>
  );
}

export default App;
