import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Toast } from 'primereact/toast';
// import { HelpBlock,FormGroup,FormControl,ControlLabel } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import axios from 'axios';
import "primereact/resources/themes/md-light-indigo/theme.css"

const apiBaseUrl = "http://localhost:5000/api/";

const  AboutUs = () => {
  const selectedContent = {
    date: '2023-08-13',
    email: 'info@turnupcentral.co.za'
    // Add other properties as needed
  };

  
return (

<div
  className="col-12 surface-0"
  style={{ padding: "60px", paddingTop: "10px", textAlign:'left'}}
><h1>About Us</h1>

<p>Welcome to Turnup Central, your go-to source for the latest updates on South African TV shows, motivational content, and local events in your area.</p>

<h2>Our Mission</h2>
<p>At Turnup Central, our mission is to keep you connected to the exciting world of South African television. We're dedicated to bringing you the latest news and updates on popular TV shows such as Skeem Saam, Dlozi Lam, Mamazala, and more.</p>

<h2>Who We Are</h2>
<p>Founded in 2023 by passionate enthusiasts, Turnup Central is a dynamic team that shares a common love for South African entertainment. We're committed to delivering fresh, accurate, and engaging content that keeps you informed and entertained.</p>

<h2>What We Offer</h2>
<p>Here's what you can expect from Turnup Central:</p>
<ul>
    <li>Timely Updates: Stay in the loop with the latest episodes, plot twists, and developments in your favorite South African TV shows.</li>
    <li>Motivational Content: We believe in the power of motivation. Find inspiring stories, articles, and videos that uplift and encourage personal growth.</li>
    <li>Local Event Covverage: Discover exciting events happening in your area. Whether it's concerts, festivals, or community gatherings, we'll keep you informed about what's happening around you. And</li>
    <li>Much more comming soon...</li>
</ul>

<h2>Contact Us</h2>
<p>If you have any questions, suggestions, or just want to say hello, feel free to contact our team:</p>
<p>Contact Person: Thabang</p>
<p>Cell Number: <a href="tel:+27671464289">067 146 4289</a></p>
<p>Email: <a href="mailto:info@turnupcentral.co.za">info@turnupcentral.co.za</a></p>

</div>


);


}

export default AboutUs