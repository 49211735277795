import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import "./TvShows.css";
import Video from "./Video";
import DownloadFromYoutube from "../components/DownloadFromYoutube";
import { LinkContainer } from "react-router-bootstrap";
import ContentService from "../Service/ContentService";
import BoxShows from "./BoxShows";

export default function WatchOurPerfectWedding() {
  const contentService = new ContentService();
  const [show, setShow] = useState(null);
  const [selectedContent, setSelectedContent] = useState({});
  const [isDefaultView, setIsDefaultView] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const parseUrlQuery = (value) => {
    var urlParams = new URL(value).searchParams;
    return Array.from(urlParams.keys()).reduce((acc, key) => {
      acc[key] = urlParams.getAll(key);
      return acc;
    }, {});
  };

  useEffect(() => {
    const { id } = parseUrlQuery(window.location);
    const pathName = window.location.pathname.replace("/", "");

    if (id) {
      contentService.GetEpisodesById(id[0])
        .then((res) => {
          const date = new Date("2023-06-08T21:37:36.480305");
          const formattedDate = date.toLocaleDateString("en-US", { day: "numeric", month: "long", year: "numeric" });
          res.date = formattedDate;

          setSelectedContent(res);
          setIsDefaultView(false);
        })
        .catch((error) => {
          console.log("Error loading Our Perfect Wedding episode with id " + id, error);
          setNotFound(true);
        });
    } else {
      contentService.GetShowEpisodesByUrlPath(pathName)
        .then((res) => {
            setShow(res);
            setIsDefaultView(true);
        })
        .catch((error) => {
            setShow([]);
          console.log("Error loading Show episodes. ", error);
        });
    }
  }, []);

  const toggleShowsView =() =>{
    window.location.href="/tv-shows"
}

  return (
    <div className="grid" style={{ margin: "30px", textAlign: "start" }}>
    
    {(isDefaultView && !notFound )&& <BoxShows show={show} back={toggleShowsView}/>}

      {!isDefaultView && !notFound && <div className="col-12 md:col-12 lg:col-12">
        <LinkContainer to="/tv-shows" style={{ color: "#5F9EA0" }}>
          <Button
            label="Back"
            severity="Primary"
            text
            icon="pi pi-arrow-left"
          />
        </LinkContainer>
        <div
          className="col-12 surface-0"
          style={{ padding: "60px", paddingTop: "10px" }}
        >
          <h1 className="font-medium text-5xl text-900 mb-3">Our Perfect Wedding</h1>
          <h2 className="text-400 text-2xl  mb-5">
            Our Perfect Wedding latest episode {selectedContent.date}
          </h2>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Title</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {selectedContent.videoTitle}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Genre</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <Chip label="Reality" className="mr-2" />
                <Chip label="Spirituality" className="mr-2" />
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">Date</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {selectedContent.date}
              </div>
            </li>
            {/* KEY FUNCTIONALITY */}
            {/* <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">
                Download Episode
              </div>

              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {selectedContent && (
                  <DownloadFromYoutube payload={selectedContent} />
                )}
              </div>
            </li> */}
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
              <div className="text-500 w-6 md:w-2 font-medium">About</div>
              <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                {selectedContent.description}
              </div>
            </li>
          </ul>
        </div>

        <div className="col-12 surface-0" style={{ textAlign: "center" }}>
          {selectedContent.watchLink && (
            <Video watchLink={selectedContent.watchLink} />
          )}
        </div>
      </div>}

    {notFound && <div className="text-600 w-full flex  justify-content-center">
    
    <span><i className="pi pi-spin pi-cog" style={{ fontSize: '2rem', marginRight:'10px'}}></i> </span>
    <span> Episode not found  </span>
    </div>}
    </div>
  );
}
