import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

export default function Video(props) {
    
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    
  return (
       <> {!isMobile ? 
        <div dangerouslySetInnerHTML={{ __html: `<iframe width='780' height='415' style="border-radius:15px" src=${props.watchLink} title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen />`}} /> :
        <div dangerouslySetInnerHTML={{ __html: `<iframe width='100%' height='100%' src=${props.watchLink} title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen />`}} />}
    </> 
  );
}