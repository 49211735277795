import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import DownloadService from "../Service/DownloadService";
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';
        

export default function DownloadFromYoutube(props) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const downloadservice = new DownloadService();
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    // //TODO: Save-from.net implementation
    // downloadservice.downloadYoutubeVideo(props.payload).then(data => {
    //     let links = [];

    //     data.url.forEach(link => {
    //     if(link.ext === "mp4" && link.no_audio === false)
    //         links.push(link)
    //     });

    //     console.log("Data", links)
    //     setDownloadLinks(links);
    //     setIsLoading(false)

    // }).catch(function (error) {
    //         console.log(error.response);
    // });


    //TODO: Enable this functionality for downloading Multiple Format
    // let video =  props.payload;

    // downloadservice.downloadYoutubeVideo(video.youtubeLink).then(res => {
    //     let links = [];

    //     console.log(res);
        
    //     res.data.formats.forEach(link => {
    //     if(link.container === "mp4")
    //     console.log(link)
    //         links.push(link)
    //     });

    //     setDownloadLinks(links);
    //     setIsLoading(false)

    // }).catch(function (error) {
    //         console.log("Error", error);
    // });

    setIsLoading(false);
  }, []);
  
  return (
       <div className="grid">

        {isLoading && <div className="card flex justify-content-center">
            <ProgressSpinner style={{width: '50px', height: '50px'}} />
        </div>}
         
         {/* Enable for Multiple format downloads */}
           {/* {!isLoading && downloadLinks.map(link => {
             return <a  text raised key={link.itag} 
             href={link.url}
             Download = "test_image"
             
             title="Dlozi’lami 25 April 2023 Full HD Episode" role="button" aria-pressed="true"
             >
                 <Button key={link.itag} label={"Download " + link.qualityLabel } icon="pi pi-download" text className=" p-button-secondary" raised/>
             </a>
           })} */}
        
        {!isLoading && <a  text raised href={props.payload.downloadLink}>
                 <Button label={"Download "} icon="pi pi-download" text className=" p-button-secondary" raised/>
             </a>
           }
       </div>
  );
}
